
import { Vue, Component } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { Socket } from 'vue-socket.io-extended';
import { SOCKET_EVENTS, SURVEY_STATUS } from '@/enum/enums';

enum Status {
  BAD_ANSWER = 'BAD_ANSWER',
  COMPLETE = 'COMPLETE',
  DUPLICATE = 'DUPLICATE',
  JOIN = 'JOIN',
  NONE = 'NONE',
  QUOTA_FULL = 'QUOTA_FULL',
  TARGETING = 'TARGETING',
  TERMINATE = 'TERMINATE',
  TERMINATE_WITH_NO_POINT = 'TERMINATE_WITH_NO_POINT',
  TOTAL = 'TOTAL',
}

interface Chart {
  date: string,
  status: { [key in Status]: number }
}

@Component({
  components: {
    VueApexCharts,
  },
})
export default class CreatedRespondentView extends Vue {

  @Socket(SOCKET_EVENTS.SURVEY_ROUTE_OUT)
  async routeOut(data: { surveyId: string, status: SURVEY_STATUS }) {
    console.log(data);
  }

  show = {
    table: true,
    chart: true,
  };
  term = 'day';
  chart: Chart[] = [];

  date: string[] = [];
  status: Status[] = [];

  options: {
    xaxis: {
      categories: string[]
    }
  } = {
    xaxis: {
      categories: [],
    },
  };

  series: { data: number[], name: string }[] = [];

  async created(): Promise<void> {
    await this.load();
  }

  async load(): Promise<void> {
    this.loading = true;
    try {
      this.series = [];
      const {
        result,
        data,
        message,
      } = await this.axios.get(`/project/view-chart/${ this.surveyId }`, { params: { term: this.term } });

      if (!result) throw message;

      const { chart, date, status } = data;
      this.chart = chart;
      this.date = date;
      this.status = status;
      this.options.xaxis.categories = this.chart.map(r => r.date);
      this.status.forEach(r => {
        const tmp: number[] = [];
        this.chart.forEach(c => {
          tmp.push(c.status[r]);
        });
        const name = this.$t(`PROJECT.CREATED.RESPONDENT.${ r }`) as string;
        this.series.push({ data: tmp, name });
      });
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  get sum(): {
    TERMINATE_WITH_NO_POINT: number,
    COMPLETE: number,
    QUOTA_FULL: number,
    TERMINATE: number,
    JOIN: number,
  } {
    return {
      COMPLETE: this.chart.map(r => r.status.COMPLETE).reduce((a, c) => a + c, 0),
      TERMINATE: this.chart.map(r => r.status.TERMINATE).reduce((a, c) => a + c, 0),
      QUOTA_FULL: this.chart.map(r => r.status.QUOTA_FULL).reduce((a, c) => a + c, 0),
      JOIN: this.chart.map(r => r.status.JOIN).reduce((a, c) => a + c, 0),
      TERMINATE_WITH_NO_POINT: this.chart.map(r => r.status.TERMINATE_WITH_NO_POINT).reduce((a, c) => a + c, 0),
    };
  }
}
