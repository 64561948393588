
import { Vue, Component } from 'vue-property-decorator'
import CreatedRespondentLog from './log.vue'
import CreatedRespondentView from './view.vue'
import CreatedNav from "@/pages/project/created/created-nav.vue";
import RegistrationStep from "@/pages/project/components/registration-step.vue";
import { mixins } from 'vue-class-component'
import { CreatedMixin } from '@/mixins/created-mixin'

@Component({
  components: {
    RegistrationStep,
    CreatedNav,
    CreatedRespondentLog,
    CreatedRespondentView,
  },
})
export default class Respondent extends mixins(Vue, CreatedMixin) {
  created() {
    (async() => {
      await this.mixinsLoad()
    })()
  }
}
