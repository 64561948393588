
import { Component, Vue } from 'vue-property-decorator'

interface Status {
  list: string[],
  selected: string,
  selectedLabel: string,
}

@Component({})
export default class CreatedRespondentLog extends Vue{
  private downloading = false;
  private downloadFileSize = 0;
  type = 'userID'
  status: Status = {
    list: [],
    selected: 'ALL',
    selectedLabel: 'ALL',
  }

  changeStatus(status: string, label: string) {
    this.status.selected = status;
    this.status.selectedLabel = label;
  }

  created() {
    (async () => {
      await this.load();
    })()
  }

  async load() {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get(`/project/enums/${ this.surveyId }`);

      if (!result) throw message;

      const { status } = data;
      console.log(status);
      this.status.list = status;
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  async logDown() {
    try {
      this.downloading = true;
      const { result, data, message, headers } = await this.axios.get(`/project/respondent-log/${ this.surveyId }`, {
        params: {
          status: this.status.selected
        },
        onDownloadProgress: (progressEvent) => {
          const { loaded } = progressEvent;
          this.downloadFileSize = loaded;
        },
        responseType: 'blob'
      });
      // await new Promise((resolve) => setTimeout(resolve, 1e3 * 3));
      if (!result) throw message;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `log-${new Date().getTime()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      this.validationMessages(e);
    } finally {
      this.downloading = false;
      this.downloadFileSize = 0;
    }
  }

}
